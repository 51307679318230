import React, { useState } from "react";
import { submitDataToAirtable } from "../../services/airtable";
import "./Form.css";

const Form = ({ scannedData }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();
    setSuccess(null);
    setError(null);

    try {
      const result = await submitDataToAirtable(scannedData);
      setSuccess(`Data submitted to Airtable: ${JSON.stringify(result)}`);
    } catch (err) {
      setError(`Error submitting data to Airtable: ${err}`);
    }
  }; 
  return (
    <form className="Form" onSubmit={handleSubmit}>
      {success && <div className="alert alert-success">{success}</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="form-group">
        <label htmlFor="scannedData">Scanned Data:</label>
        <input
          type="text"
          id="scannedData"
          className="form-control"
          value={scannedData}
          readOnly
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

export default Form;
