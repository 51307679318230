import React, { useState } from "react";
import QRScanner from "./components/QRScanner/QRScanner";
import Form from "./components/Form/Form";
import "./App.css";

const App = () => {
  const [scannedData, setScannedData] = useState("");

  const handleScan = data => {
    setScannedData(data);
  };

  return (
    <div className="App">
      <QRScanner onScan={handleScan} />
      <Form scannedData={scannedData} />
    </div>
  );
};

export default App;
