// airtable.js
import axios from "axios";

const AIRTABLE_API_KEY = "keyIszeYVPo5ZLVKl";
const AIRTABLE_BASE_ID = "appywrOPPjL7HcVpL";
const AIRTABLE_TABLE_NAME = "Warehouse-Week6";
const AIRTABLE_FIELD_NAME = "Bag Scanned";

export const submitDataToAirtable = async data => {
  try {
    const payload = {
      fields: {
        [AIRTABLE_FIELD_NAME]: data
      }
    };

    console.log("Request payload:", payload);

    const response = await axios.post(
      `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          "Content-Type": "application/json"
        }
      }
    );

    console.log("Response data:", response.data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
