import React, { useState, useEffect } from "react";
import qrScanner from "qr-scanner";
import "./QRScanner.css";

const QRScanner = ({ onScan }) => {
  const [scanner, setScanner] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const videoElem = document.createElement("video");
    videoElem.width = 500;
    videoElem.height = 500;
    videoElem.style = "width: 100%; height: 100%; object-fit: cover;";
    document.getElementById("scanner-container").appendChild(videoElem);

    const scanner = new qrScanner(videoElem, result => {
      onScan(result);
    });

    try {
      scanner.start();
      setScanner(scanner);
    } catch (e) {
      console.error(e);
      setError(e);
    }

    return () => {
      scanner.destroy();
    };
  }, [onScan]);

  useEffect(() => {
    if (!scanner) return;
    scanner.setGrayscaleWeights(0.2126, 0.7152, 0.0722);
    scanner.setInversionMode("both");
  }, [scanner]);

  if (error) {
    return (
      <div className="error">
        <p>An error occurred while trying to start the QR scanner:</p>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <div className="QRScanner">
      <div id="scanner-container"></div>
    </div>
  );
};

export default QRScanner;
